import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export interface ISiteConfigProps {
    siteConfig: {
      nodes: {isFranchiseeSelectorEnabled: boolean}[];
    };
  }

export const useFranchiseeSelectorConfig = () => {
    const data: ISiteConfigProps = useStaticQuery(
        graphql`
            query SiteConfigFranchiseeSelectorConfigQuery {
                siteConfig: allDatoCmsSiteConfig {
                    nodes {
                        isFranchiseeSelectorEnabled
                    }
                }
            }`
        );
    const isFranchiseeSelectorEnabled = data?.siteConfig?.nodes[0]?.isFranchiseeSelectorEnabled;
    
    return { isFranchiseeSelectorEnabled }
}   